.teambuilder ol {
    position: relative;
    padding-inline-start: 20px;
}

.teambuilder li {
    position: relative;
    cursor: grab;
}

.teambuilder input[type="text"] {
    color: var(--color-text);
    background-color: var(--color-primary-light);
    border: none;
}

.teambuilder .player {
    display: flex;
    align-items: center;
    border-radius: 19px;
    box-shadow: var(--elevation-1);
    gap: 4px;
    justify-content: space-between;

    margin-bottom: 4px;
}

.teambuilder .player.home {
    background: var(--color-primary-dark);
    color: var(--color-text);
}

.teambuilder .player.away {
    background: var(--color-secondary);
    color: var(--color-text);
}

.teambuilder .player .drag-handle {
    height: 16px;
    width: 16px;
    padding: 4px;
    border-radius: 50%;
    fill: var(--color-text);
    margin-left: 4px;
}

.teambuilder .player button {
    border-radius: 50%;
    aspect-ratio: 1;
    border: none;
    margin: 4px;
    display: flex;
    place-items: center;
    padding: 4px;
}

.teambuilder .player button svg {
    height: 16px;
    width: 16px;
}

.teambuilder .player .name {
    padding: 4px 8px;
    flex: 1;
    border-radius: 3px;
}

.teambuilder .player .position {
    padding: 4px 8px;
    background: var(--color-quadinary);
    color: var(--color-text-dark);
    border-radius: 19px;
}

.teambuilder .add-player {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 8px;
}

.teambuilder .add-player .name-input {
    flex: 1;
    font-size: 16px;
    line-height: 16px;
    
    border-radius: 3px;
}

.teambuilder .add-player .add-btn {
    padding: 0 8px;
}
