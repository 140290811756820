.about {
    color: var(--color-text);
    text-align: center;
}

.about h1 {
    font-size: 1.8em;
}

.about a {
    color: var(--color-text);
}
