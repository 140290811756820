.pitch-menu {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    gap: 16px;
}

.pitch-menu > div {
    display: flex;
    gap: 16px;
    /* background-color: white; */
    padding: 4px;
    border-radius: 32px;
    justify-content: center;
    align-items: center;
}

.pitch-menu div.row {
    flex-direction: row-reverse;
    width: fit-content;
}

.pitch-menu div.col {
    flex-direction: column-reverse;
}

.pitch-menu button.pitch-btn {
    border-radius: 50%;
    border: none;
    color: var(--color-text);
    font-weight: 600;
    box-shadow: var(--elevation-2);
    font-size: 16px;
    width: 50px;
    height: 50px;
    cursor: pointer;
    overflow: hidden;
    text-align: center;
    padding: 0;
}

.pitch-menu button.pitch-btn:hover:not(:disabled),
.pitch-menu button.pitch-btn:focus:not(:disabled) {
    outline: 2px solid var(--color-quadinary);
}

.pitch-menu button.pitch-btn div {
    font-size: .9em;
    font-weight: 400;
    color: var(--color-text-subtle);
}

.pitch-menu button.pitch-btn:disabled,
.pitch-menu button.pitch-btn.justpitch:disabled,
.pitch-menu button.pitch-btn.inplayout:disabled,
.pitch-menu button.pitch-btn.inplay:disabled,
.pitch-menu button.pitch-btn.inplayboth:disabled,
.pitch-menu button.pitch-btn.action:disabled {
    background: var(--color-disabled);
    color: var(--color-text-disabled);
    box-shadow: none;
    font-weight: initial;
    cursor: not-allowed;
}

.pitch-menu button.pitch-btn.justpitch:disabled,
.pitch-menu button.pitch-btn.inplayout:disabled,
.pitch-menu button.pitch-btn.inplay:disabled,
.pitch-menu button.pitch-btn.inplayboth:disabled {
    height: 25px;
    width: 25px;
    margin: 12.5px;
}

.pitch-menu button.pitch-btn.justpitch {
    background: var(--color-primary);
}
.pitch-menu button.pitch-btn.justpitch:active:not(:disabled) {
    background: var(--color-primary-dark);
}

.pitch-menu button.pitch-btn.inplayout {
    background: var(--color-tertiary);
}
.pitch-menu button.pitch-btn.inplayout:active:not(:disabled) {
    background: var(--color-tertiary-dark);
}

.pitch-menu button.pitch-btn.inplay {
    background: var(--color-secondary);
}
.pitch-menu button.pitch-btn.inplay:active:not(:disabled) {
    background: var(--color-secondary-dark);
}

.pitch-menu button.pitch-btn.inplayboth {
    background: var(--color-quadinary);
    color: var(--color-text-dark);
}
.pitch-menu button.pitch-btn.inplayboth:active:not(:disabled) {
    background: var(--color-quadinary-dark);
}
.pitch-menu button.pitch-btn.inplayboth:not(:disabled) div {
    color: var(--color-text-subtle-dark);
}
.pitch-menu button.pitch-btn.inplayboth:hover:not(:disabled),
.pitch-menu button.pitch-btn.inplayboth:focus:not(:disabled) {
    outline-color: var(--color-secondary);
}

.pitch-menu button.pitch-btn.action {
    background: var(--color-quadinary);
}
.pitch-menu button.pitch-btn.action > div {
    color: var(--color-text-dark);
    font-size: 8px;
}
.pitch-menu button.pitch-btn.action:active:not(:disabled) {
    background: var(--color-quadinary-dark);
}
