.load {
    color: var(--color-text);
}

.load ul {
    list-style: none;
    padding: 16px;
    margin: 0;
}

.load .save {
    padding: 16px;
    margin-bottom: 8px;
    box-shadow: var(--elevation-2);
    font-size: 24px;
    background-color: var(--color-primary-light);
    color: var(--color-text);
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.load .hint {
    margin-bottom: 64px;
}
