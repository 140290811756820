.newgame {
    color: var(--color-text);
    padding: 16px;
    overflow: auto;
}

.newgame .rules,
.newgame .config {
    margin-bottom: 8px;
}

.newgame h2 {
    margin-bottom: 4px;
}

.newgame .validation {
    margin-top: 16px;
    color: var(--color-quadinary);
    width: 100%;
    text-align: center;
}

.newgame button.start {
    height: 40px;
    width: 80%;
    margin: 32px;
}

.newgame button.start:disabled {
    margin-top: 0;
}

.newgame button.swap-teams {
    display: flex;
    align-items: center;
    margin: 16px;
}

.newgame button.swap-teams svg {
    height: 16px;
}

.newgame .team-header {
    display: flex;
    gap: 8px;
}

.newgame .team-header .team-name {
    color: var(--color-text);
    background-color: var(--color-primary-light);
    border: none;
    padding: 0 4px;
    margin: 16px 0;
}

.newgame .team-header .team-name-btn {
    background: transparent;
    border: none;
    color: var(--color-text);
    text-decoration: underline;

    margin: 16px 0;
}
