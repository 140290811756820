.manage-share {
    padding: 16px;
    color: var(--color-text);
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.manage-share .qr-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 0;
}

.manage-share input {
    border: none;
    color: var(--color-text);
    background: var(--color-primary);
}
