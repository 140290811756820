.scoreboard {
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    scrollbar-width: 0;
}

.scoreboard::-webkit-scrollbar {
    display: none;
}

.scoreboard .boxScoreContainer {
    width: 100%;
    overflow-x: auto;
}

/* bases */
.scoreboard .bases {
    position: relative;
    margin: 0 auto;
    width: 100%;
    aspect-ratio: 1;
    overflow: hidden;
    --base-size: calc(100% * .28);
    background: var(--color-primary);
    clip-path: inset(5% 0% 12%);
    margin-bottom: -12%;
    margin-top: -5%;
}

.crosshair {
    position: absolute;
    height: 100%;
    width: 100%
    /* box-sizing: border-box; */
}

.crosshair.vertical {
    width: 50%;
    border-right: 1px solid black;
}

.crosshair.horizontal {
    height: 50%;
    border-bottom: 1px solid black;
}

.scoreboard .bases .base > div {
    height: var(--base-size);
    width: var(--base-size);
    background: var(--color-primary);
    border: 8px solid var(--color-disabled);
    transform: translate(-50%, -50%) rotate(45deg);
    box-sizing: border-box;
}

.scoreboard .bases .base > div.occupied {
    border: none;
    background: var(--color-quadinary);
    box-shadow: var(--elevation-2);
}

.scoreboard .bases .base,
.scoreboard .bases .homeplate {
    position: absolute;
    height: 100%;
    width: 100%;
}

.scoreboard .bases .base.firstbase {
    transform: translate(calc(100% - var(--base-size)), 50%);
}

.scoreboard .bases .base.secondbase {
    transform: translate(50%, var(--base-size));
}

.scoreboard .bases .base.thirdbase {
    transform: translate(var(--base-size), 50%);
}

.scoreboard .bases .homeplate {
    transform: translate(50%, calc(100% - var(--base-size)));
}

.scoreboard .bases .homeplate > div {
    height: calc(var(--base-size) * .70);
    width: calc(var(--base-size) * .70);
    background: var(--color-disabled);
    transform: translate(-50%, -50%);
    clip-path: polygon(100% 0, 100% 50%, 50% 100%, 0 50%, 0 0);
}

/* count */
.scoreboard .count {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    text-align: center;
    font-size: 24px;
    background: var(--color-primary);
    color: var(--color-text);
}

.scoreboard .count div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.scoreboard .count div span.icon {
    font-size: 12px;
    line-height: 24px;
}

.scoreboard .players {
    background: var(--color-primary);
    color: var(--color-text);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    text-align: center;
    padding: 4px;
    gap: 4px;
}

.scoreboard .players div {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 32px 20px;
}

.scoreboard .players a {
    padding: 6px 4px;
    border-radius: 19px;
    background: var(--color-quadinary);
    font-weight: 600;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-decoration: none;
    grid-column: 1 / 3;
    color: var(--color-text);
}

.scoreboard .players div.home a {
    background: var(--color-primary-dark);
}
.scoreboard .players div.away a {
    background: var(--color-secondary);
}

.scoreboard .players label {
    color: var(--color-text-subtle);
    font-size: .8em;
    place-self: baseline;
    margin-left: 16px;
}
