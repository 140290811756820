.manage-export {
    color: var(--color-text);
}

.manage-export ul {
    list-style: none;
    padding: 16px;
    margin: 0;
    margin-bottom: 64px;
}

.manage-export ul a {
    text-decoration: none;
}
