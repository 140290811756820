.manage-roster {
    color: var(--color-text);
}

.manage-roster.home .page-header {
    background: var(--color-primary-dark);
}

.manage-roster.away .page-header {
    background: var(--color-secondary);
}

.manage-roster li::marker,
.manage-roster li {
    width: calc(100% - 16px);
    margin-bottom: 8px;
}

.manage-roster .row {
    display: flex;
    gap: 8px;
    align-items: center;
    height: 2em;
    padding: 0 8px;
    background: var(--color-primary-light);
}

.manage-roster .row .name {
    width: 33%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.manage-roster .row .position {
    text-align: center;
}

.manage-roster .row .pa-stat {
    justify-self: flex-end;
    text-align: right;
    flex: 1;
}

.manage-roster .actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;

    margin-top: 32px;
}

.manage-roster .actions .update-btn {
    height: 32px;
    width: 60%;
}

.manage-roster .validation {
    font-style: italic;
}

.manage-roster .actions .save-check {
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1.1;
    display: grid;
    grid-template-columns: 1em auto;
    gap: 0.5em;
}

/* .manage-roster .actions .already-saved {} */

.manage-roster .actions .already-saved a,
.manage-roster .actions .already-saved a:visited {
    text-decoration: none;
    color: var(--color-text);
    padding: 4px 8px;
    border-radius: 1em;
    background: var(--color-primary-light);
    box-shadow: var(--elevation-1);
}


