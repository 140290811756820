.App {
  background: var(--color-primary);
  height: 100%;
  overflow: hidden;
}

.content {
  max-width: 425px;
  margin: 0 auto;
  overflow: auto;
  height: calc(100vh - 56px); /* max nav height */
  position: relative;
}

.nav-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
