.stats {
    color: var(--color-text);
    padding: 8px;
}

.stats h1 {
    font-weight: 400;
    text-align: center;
}

.stats nav {
    display: flex;
    gap: 16px;
}

.stats nav a {
    text-decoration: none;
    color: var(--color-text);
}

.stats nav a.active {
    border-bottom: 2px solid var(--color-text);
}

.stats h2 {
    font-weight: 400;
    margin-bottom: 8px;
}

.stats .team {
    display: flex;
    flex-direction: column;
    place-content: center left;
}

.stats .team table {
    border: 1px solid black;
    border-collapse: collapse;
}

.stats .team table td,
.stats .team table th {
    border: 1px solid black;
    padding: 8px;
    text-align: center;
}

.stats .team.hometeam table thead tr td {
    background: var(--color-primary-dark);
}

.stats .team.awayteam table thead tr td {
    background: var(--color-secondary);
}
