.menuitem {
    padding: 24px 16px;
    margin-bottom: 8px;
    box-shadow: var(--elevation-2);
    font-size: 24px;
    background-color: var(--color-primary-light);
    color: var(--color-text);
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.menuitem div {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.menuitem svg {
    fill: var(--color-text);
    height: 32px;
}

.menuitem span {
    font-size: .6em;
}
