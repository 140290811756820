.nav {
    position: fixed;
    bottom: 0;
    height: 56px;
    width: 100%;
    max-width: 425px;
    gap: 2px;
    background-color: var(--color-primary-dark);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

/* .nav button.nav-btn {
    height: 100%;
    background: var(--color-primary-light);
    color: var(--color-text);
    font-size: 24px;
    border: none;
    
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.nav button.nav-btn svg {
    fill: var(--color-text);
    height: 36px;
}

.nav button.nav-btn svg.left {
    transform: rotate(.25turn);
    margin-left: -16px;
}

.nav button.nav-btn svg.right {
    transform: rotate(-.25turn);
    margin-right: -16px;
}

.nav button.nav-btn.pitch {
    height: 86px;
    width: 86px;
    border-radius: 50%;
    background: var(--color-secondary);
    box-shadow: var(--elevation-2);
    cursor: pointer;
    padding: 4px;
    position: absolute;
    right: 8px;
    bottom: 8px;
}

.nav button.nav-btn.pitch:active:not(:disabled) {
    background: var(--color-secondary-dark);
}

.nav button.nav-btn:hover:not(:disabled),
.nav button.nav-btn:focus:not(:disabled) {
    outline: 2px solid var(--color-quadinary);
}


 */


.nav .link {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--color-text);
    text-decoration: none;
}

.nav .link.active {
    background: var(--color-primary-light);
}

.nav .link svg {
    height: 32px;
}

.nav .link span {
    font-size: 12px;
}

.nav button.nav-btn.pitch {
    height: 86px;
    width: 86px;
    border-radius: 50%;
    background: var(--color-secondary);
    color: var(--color-text);
    font-size: 18px;
    box-shadow: var(--elevation-2);
    border: none;
    cursor: pointer;
    padding: 4px;
    position: absolute;
    right: 8px;
    bottom: 8px;
}

.nav button.nav-btn:disabled {
    background: transparent;
    box-shadow: none;
    color: var(--color-text-subtle);
    border: 4px solid var(--color-text-subtle);
    cursor: not-allowed;
}
