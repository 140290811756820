.manual .page-content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 8px;

    color: var(--color-text);
}

.manual .inning > div {
    display: inline-block;
}

.manual .boxScoreContainer {
    width: 100%;
    overflow-x: auto;
}

.manual .count {
    display: flex;
    align-items: center;
}

.manual .count label {
    margin-right: 4px;
}

.manual .count input {
    margin-right: 16px;
}

.manual .bases > div {
    display: inline-block;
    margin-right: 16px;
}

.manual .atBat {
    display: flex;
    align-items: center;
}

.manual .atBat label {
    margin-right: 4px;
}

.manual button.submit {
    height: 30px;
    margin-bottom: 64px;
}
