.menu ul {
    list-style: none;
    padding: 16px;
    margin: 0;
    margin-bottom: 64px;
}

.menu .menuitem .iconed {
    display: flex;
    flex-direction: row;
    font-size: 24px;
    gap: 8px
}
