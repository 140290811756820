body {
    --color-primary: #2e7d32;
    --color-primary-light: #60ad5e;
    --color-primary-dark: #005005;
    --color-secondary: #1976d2;
    --color-secondary-light: #63a4ff;
    --color-secondary-dark: #004ba0;
    --color-tertiary: #dd2c00;
    --color-tertiary-light: #ff6434;
    --color-tertiary-dark: #a30000;
    --color-quadinary:  #ffb300;
    --color-quadinary-light: #ffe54c;
    --color-quadinary-dark: #c68400;
    --color-disabled: #e0e0e0;
    --color-text: #ffffff;
    --color-text-dark: #000000;
    --color-text-subtle: #bdbdbd;
    --color-text-subtle-dark: #424242;
    --color-text-disabled: #9e9e9e;

    --elevation-1: 0 3px 4px 0 rgba(0,0,0,0.14), 0 3px 3px -2px rgba(0,0,0,0.12), 0 1px 8px 0 rgba(0,0,0,0.20);
    --elevation-2: 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.20);
}
