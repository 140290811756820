.team-summary {
    color: var(--color-text);
}

.team-summary .share {
    padding: 16px;
    color: var(--color-text);
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.team-summary .share .qr-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 0;
}

.team-summary .share input {
    border: none;
    color: var(--color-text);
    background: var(--color-primary);
}

.team-summary .remove {
    background: var(--color-primary);
    color: var(--color-text);
    padding: 8px;
    border: 1px solid var(--color-primary-light);
    width: 100%;

    margin-top: 32px;
}
