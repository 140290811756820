.substitute {
    color: var(--color-text);
}

.substitute ul {
    list-style: none;
    padding: 16px;
    margin: 0;
}

.substitute ul li {
    padding: 16px;
    margin-bottom: 8px;
    box-shadow: var(--elevation-2);
    font-size: 24px;
    background-color: var(--color-primary-light);
    color: var(--color-text);
    border-radius: 4px;
}

.substitute ul li[role="button"] {
    cursor: pointer;
}

.substitute div.prompt {
    padding: 4px 16px;
    margin-bottom: 8px;
}

.substitute div.prompt label {
    margin-right: 8px;
}

.substitute div.prompt span {
    padding: 4px 8px;
    background: var(--color-disabled);
    color: var(--color-text-dark);
    border-radius: 19px;
}

.substitute div.summary {
    padding: 4px 16px;
}

.substitute div.summary > div {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.substitute div.summary div.player {
    margin-right: 8px;
    padding: 4px 8px;
    background: var(--color-secondary);
    border-radius: 19px;
    box-shadow: var(--elevation-1);
}
.substitute div.summary div.position {
    margin-left: 8px;
    padding: 4px 8px;
    background: var(--color-quadinary);
    color: var(--color-text-dark);
    border-radius: 19px;
    box-shadow: var(--elevation-1);
}


.substitute .submit {
    margin-top: 32px;
    width: 100%;
    display: flex;
    place-content: center;
}

.substitute .submit button {
    height: 32px;
    width: 50vw;
}
